import { ReactNode, createContext,useState } from "react";

type ContextType = {
    name: string;
    idusuario:String;
    setName: (n:string) => void;
    setIdUsuario: (n:string) => void;
}

export const UsuarioLogadoContext = createContext<ContextType | null>(null);


export const UsuarioLogadoProvider = ({ children}: {children: ReactNode}) => {

    const[name, setName] = useState('');
    const[idusuario, setIdUsuario] = useState('');

    return (
        <UsuarioLogadoContext.Provider value={{name, idusuario, setName, setIdUsuario}}>
            {children}
        </UsuarioLogadoContext.Provider>
    )

}
