import Cabecalho from "../../components/cabecalho";

function SobreDirecao (){

return(
    <div>
        <Cabecalho titulo="home/SobreDirecao" />     
        <h1>Sobre DIRETORIA</h1>
    </div>
    )
}

export default SobreDirecao;