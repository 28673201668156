import Cabecalho from "../../components/cabecalho";

function SobreProfessor (){

return(
    <div>
        <Cabecalho titulo="home/SobreProfessores" />     
        <h1>Sobre PROFESSORES</h1>
    </div>
    )
}

export default SobreProfessor;